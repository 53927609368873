import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';

import {
  EnumsSettingsName,
  type ModelsSettingsPage,
} from '@lp-lib/api-service-client/public';

import { useUserAnalytics } from '../analytics/user';
import { AdminAccess } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { SectionedPageManager } from '../components/PageManager/SectionedPageManager';
import { PageManagerUtils } from '../components/PageManager/utils';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import { AdminView } from '../pages/Admin/AdminView';
import { apiService } from '../services/api-service';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { name } = action.params;
  if (!PageManagerUtils.IsPageName(name))
    throw new Response('Not Found', { status: 404 });
  const page = (await apiService.page.get(name)).data.page;

  return {
    pageName: name,
    page,
  };
}

export function Component() {
  const { pageName, page } = useLoaderData<typeof clientLoader>();
  const navigate = useNavigate();

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
    <UserAccess />,
    <AdminAccess />,
  ];

  const handleConfirm = async (page: ModelsSettingsPage) => {
    await apiService.settings.updateValue(EnumsSettingsName.SettingsNamePages, {
      key: pageName,
      value: page,
    });
  };

  return (
    <ProvidersList providers={providers}>
      <AdminView className='text-white w-full p-10'>
        <SectionedPageManager
          pageName={pageName}
          page={page}
          onCancel={() => navigate(-1)}
          onConfirm={handleConfirm}
        />
      </AdminView>

      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
