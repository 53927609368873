import copy from 'copy-to-clipboard';
import pluralize from 'pluralize';
import { useCallback, useState } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  type DtoPageRow,
  type DtoPageSection,
  type DtoTag,
  type EnumsPageName,
  EnumsPageRowLayout,
  EnumsPageRowType,
} from '@lp-lib/api-service-client/public';

import { pageRowAnchor } from '../../../app/components/DynamicPage/utils';
import { SiteBaseURL } from '../../services/public';
import { type Tag, TaggedObjectType } from '../../types';
import { toDtoTag } from '../../utils/api-dto';
import { type Action, ActionSheet } from '../ActionSheet';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { CopyIcon } from '../icons/CopyIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { TagPicker } from '../Tagging';
import { useTriggerTagEditorModal } from '../Tagging/TagEditor';
import { TagUtils } from '../Tagging/utils';
import { type PageFormData } from './SectionedPageManager';
import { PageRowLayoutSelect } from './Shared';
import { PageManagerUtils } from './utils';

type FormData = {
  tag: DtoTag | null;
  layout: EnumsPageRowLayout | null;
  anchor: string;
};

function TagField(props: { filter?: (tag: Tag) => boolean }) {
  const {
    field: { value, onChange },
  } = useController<FormData, 'tag'>({
    name: 'tag',
    rules: { required: true },
  });

  return (
    <label>
      <div className='text-white text-sms mb-1'>Category</div>

      <TagPicker
        placeholder='Search for an existing Category'
        filter={props.filter}
        tags={value ? [value] : []}
        onChange={onChange}
        multi={false}
        formatMeta={formatTag}
      />
    </label>
  );
}

function LayoutField() {
  const {
    field: { value, onChange },
  } = useController<FormData, 'layout'>({
    name: 'layout',
    rules: { required: true },
  });

  return (
    <label>
      <div className='text-white text-sms mb-1'>Layout</div>
      <PageRowLayoutSelect value={value} onChange={onChange} />
    </label>
  );
}

function AnchorField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label title='The anchor is the unique identifier for the section. It is used to link to the section from other sections or pages.'>
      <h2 className='text-sms mb-1'>
        Anchor{' '}
        <span className='text-xs text-icon-gray'>
          It is used to link to the row from other pages.
        </span>
      </h2>
      <input
        className={`w-full h-10 ${
          errors.anchor ? 'field-error' : 'field'
        } mb-0`}
        {...register('anchor', {
          pattern: /^[a-z0-9-]+$/,
        })}
        placeholder='only lowercase letters, numbers, and hyphens'
      ></input>
    </label>
  );
}

function PageManagerRowCategoryEditor(props: {
  initialData?: FormData;
  filter?: (tag: Tag) => boolean;
  onCancel: () => void;
  onConfirm: (data: FormData) => void;
}) {
  const form = useForm<FormData>({
    defaultValues: props.initialData,
  });

  return (
    <FormProvider {...form}>
      <form
        className='w-full px-8 py-10'
        onSubmit={form.handleSubmit(props.onConfirm)}
      >
        <div className='text-white text-2xl text-center'>
          {props.initialData ? 'Edit' : 'Add'} Featured Category Row
        </div>

        <div className='mt-5 flex flex-col gap-4'>
          <TagField filter={props.filter} />
          <LayoutField />
          <AnchorField />
        </div>

        <div className='mt-10 flex justify-center items-center gap-10'>
          <button
            type='button'
            className='btn-secondary w-33 h-10'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button type='submit' className='btn-primary w-33 h-10'>
            Save
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export function useTriggerEditPageManagerRowCategoryModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: {
      row?: DtoPageRow;
      filter?: (tag: Tag) => boolean;
      onSave: (row: DtoPageRow) => void;
    }) => {
      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            onClose={p.internalOnCancel}
            containerClassName='w-120'
            borderStyle='gray'
          >
            <PageManagerRowCategoryEditor
              initialData={{
                tag: props.row?.tag || null,
                layout:
                  props.row?.layout || EnumsPageRowLayout.PageRowLayoutCarousel,
                anchor: props.row?.anchor || '',
              }}
              onCancel={p.internalOnCancel}
              onConfirm={(data) => {
                if (!data.tag) return;

                props.onSave({
                  id: String(data.tag.id),
                  type: EnumsPageRowType.PageRowTypeTag,
                  tag: data.tag,
                  layout: data.layout,
                  anchor: data.anchor,
                });
                p.internalOnConfirm();
              }}
              filter={(tag) => {
                if (
                  TagUtils.GetObjectsCount(
                    tag,
                    TaggedObjectType.PrimeGamePack
                  ) === 0
                )
                  return false;
                if (props.filter) return props.filter(tag);
                return true;
              }}
            />
          </ModalWrapper>
        ),
      });
    },
    [triggerModal]
  );
}

function PageManagerRowCategoryActions(props: {
  pageName: EnumsPageName;
  section: DtoPageSection;
  row: DtoPageRow;
  onEditRow: () => void;
  onEditTag: () => void;
  onDelete: () => void;
}) {
  const { formState } = useFormContext<PageFormData>();
  const defaultSection = formState.defaultValues?.sections?.find(
    (s) => s?.id === props.section.id
  );
  const defaultRow = defaultSection?.rows?.find((r) => r?.id === props.row.id);

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit-row',
      icon: <EditIcon />,
      text: 'Edit Row',
      onClick: props.onEditRow,
    },
    {
      kind: 'button',
      key: 'delete-row',
      icon: <DeleteIcon />,
      text: 'Delete Row',
      className: 'text-red-002',
      onClick: props.onDelete,
    },
    {
      kind: 'button',
      key: 'edit-tag',
      icon: <EditIcon />,
      text: 'Edit Tag',
      onClick: props.onEditTag,
    },
  ];
  const loggedInPath = PageManagerUtils.GetLoggedInPath(props.pageName);
  if (loggedInPath) {
    actions.push({
      kind: 'button',
      key: 'copy-logged-in-link',
      text: 'Copy: Logged In Link',
      icon: <CopyIcon />,
      disabled: props.row.anchor !== defaultRow?.anchor,
      onClick: () => {
        const url = new SiteBaseURL();
        url.pathname = loggedInPath;
        url.hash = pageRowAnchor(props.row);
        copy(url.toString());
      },
    });
  }
  const loggedOutPath = PageManagerUtils.GetLoggedOutPath(props.pageName);
  if (loggedOutPath) {
    actions.push({
      kind: 'button',
      key: 'copy-logged-out-link',
      text: 'Copy: Logged Out Link',
      icon: <CopyIcon />,
      disabled: props.row.anchor !== defaultRow?.anchor,
      onClick: () => {
        const url = new SiteBaseURL();
        url.pathname = loggedOutPath;
        url.hash = pageRowAnchor(props.row);
        copy(url.toString());
      },
    });
  }

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-10 h-10 border border-secondary rounded-xl !text-secondary'
    />
  );
}

function formatTag(tag: Tag) {
  const count = TagUtils.GetObjectsCount(tag, TaggedObjectType.PrimeGamePack);
  const label = TagUtils.GetObjectLabel(TaggedObjectType.PrimeGamePack, false);
  return pluralize(label, count, true);
}

export function PageMangerRowCategory(props: {
  pageName: EnumsPageName;
  section: DtoPageSection;
  row: DtoPageRow;
  tag: Tag;
  drag: ConnectDragSource;
  onDelete: () => void;
  onEdit: (row: DtoPageRow) => void;
}) {
  const { tag, drag, onDelete, onEdit } = props;

  const triggerEditRowModal = useTriggerEditPageManagerRowCategoryModal();
  const triggerEditTagModal = useTriggerTagEditorModal();

  return (
    <div className={`w-full flex items-center gap-2`}>
      <button type='button' ref={drag} className='w-3.5 h-3.5 btn cursor-move'>
        <MenuIcon />
      </button>
      <div className='flex-1 flex items-center justify-between p-3 h-10 border border-secondary rounded-xl'>
        <div className='text-sms'>{tag.name}</div>
        <div className='text-sms text-secondary'>{formatTag(tag)}</div>
      </div>
      <PageManagerRowCategoryActions
        pageName={props.pageName}
        section={props.section}
        row={props.row}
        onEditRow={() =>
          triggerEditRowModal({
            row: props.row,
            onSave: onEdit,
          })
        }
        onDelete={onDelete}
        onEditTag={() =>
          triggerEditTagModal({
            tag,
            onSave: (updated) =>
              onEdit({ ...props.row, tag: toDtoTag(updated) }),
          })
        }
      />
    </div>
  );
}

function AddCategoryModal(props: {
  onCancel: () => void;
  onConfirm: (tag: Tag) => void;
  filter?: (tag: Tag) => boolean;
}) {
  const [tag, setTag] = useState<Tag | null>(null);

  return (
    <ModalWrapper
      onClose={props.onCancel}
      containerClassName='w-156'
      borderStyle='gray'
    >
      <div className='w-full px-8 py-10'>
        <div className='text-base font-medium my-2'>
          Add a Featured Category
        </div>
        <TagPicker
          placeholder='Search for an existing Category'
          filter={props.filter}
          tags={tag ? [tag] : undefined}
          onChange={setTag}
          multi={false}
          formatMeta={formatTag}
        />

        <div className='mt-12.5 flex justify-center items-center gap-10'>
          <button
            type='button'
            className='btn-secondary w-33 h-10'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-33 h-10'
            disabled={!tag}
            onClick={() => tag && props.onConfirm(tag)}
          >
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

export function useTriggerCategorySelectionModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: {
      filter?: (tag: Tag) => boolean;
      onSave: (tag: Tag) => void;
    }) => {
      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <AddCategoryModal
            onCancel={p.internalOnCancel}
            onConfirm={(tag) => {
              props.onSave(tag);
              p.internalOnConfirm();
            }}
            filter={(tag) => {
              if (
                TagUtils.GetObjectsCount(
                  tag,
                  TaggedObjectType.PrimeGamePack
                ) === 0
              )
                return false;
              if (props.filter) return props.filter(tag);
              return true;
            }}
          />
        ),
      });
    },
    [triggerModal]
  );
}
