import { type ConnectDragSource } from 'react-dnd';

import { DeleteIcon } from '../icons/DeleteIcon';
import { MenuIcon } from '../icons/MenuIcon';

export function PageMangerRowFeaturedCarousel(props: {
  drag: ConnectDragSource;
  onDelete: () => void;
}) {
  const { drag, onDelete } = props;

  return (
    <div className={`w-full flex items-center gap-2`}>
      <button type='button' ref={drag} className='w-3.5 h-3.5 btn cursor-move'>
        <MenuIcon />
      </button>
      <div className='flex-1 flex items-center justify-between p-3 h-10 border border-secondary rounded-xl'>
        <div className='text-sms italic font-bold'>Featured Carousel</div>
        <div className='text-sms text-secondary'></div>
      </div>
      <button
        type='button'
        className='btn w-10 h-10 bg-black border border-secondary rounded-xl flex items-center justify-center text-red-002'
        onClick={onDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  );
}
