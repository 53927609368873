import copy from 'copy-to-clipboard';
import { useRef, useState } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import { useFormContext } from 'react-hook-form';

import {
  type DtoPage,
  type DtoPageRow,
  type DtoPageSection,
  type EnumsPageName,
  EnumsPageRowType,
  EnumsPageSectionType,
} from '@lp-lib/api-service-client/public';
import { assertExhaustive } from '@lp-lib/game';

import { pageSectionAnchor } from '../../../app/components/DynamicPage/utils';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { SiteBaseURL } from '../../services/public';
import { uuidv4 } from '../../utils/common';
import { type Action, ActionSheet } from '../ActionSheet';
import { DragDropList } from '../common/DragDrop';
import { CopyIcon } from '../icons/CopyIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { PlusIcon } from '../icons/PlusIcon';
import {
  PageMangerRowCategory,
  useTriggerEditPageManagerRowCategoryModal,
} from './PageManagerRowCategory';
import { PageMangerRowFeaturedCarousel } from './PageManagerRowFeaturedCarousel';
import { PageMangerRowSocialProof } from './PageManagerRowSocialProof';
import {
  PageManagerRowTagCollection,
  useTriggerEditTagCollectionModal,
} from './PageManagerRowTagCollection';
import { useTriggerPageSectionEditorModal } from './PageManagerSectionEditor';
import { type PageFormData } from './SectionedPageManager';
import { PageManagerUtils } from './utils';

function PageManagerSectionActions(props: {
  pageName: EnumsPageName;
  section: DtoPageSection;
  onEditSection: () => void;
  onDeleteSection: () => void;
}) {
  const { formState } = useFormContext<PageFormData>();

  const defaultValue = formState.defaultValues?.sections?.find(
    (s) => s?.id === props.section.id
  );

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit-section',
      icon: <EditIcon />,
      text: 'Edit Section',
      onClick: props.onEditSection,
    },
    {
      kind: 'button',
      key: 'delete-section',
      icon: <DeleteIcon />,
      text: 'Delete Section',
      className: 'text-red-500',
      onClick: props.onDeleteSection,
    },
  ];
  const loggedInPath = PageManagerUtils.GetLoggedInPath(props.pageName);
  if (loggedInPath) {
    actions.push({
      kind: 'button',
      key: 'copy-logged-in-link',
      text: 'Copy: Logged In Link',
      icon: <CopyIcon />,
      disabled: props.section.anchor !== defaultValue?.anchor,
      onClick: () => {
        const url = new SiteBaseURL();
        url.pathname = loggedInPath;
        url.hash = pageSectionAnchor(props.section);
        copy(url.toString());
      },
    });
  }
  const loggedOutPath = PageManagerUtils.GetLoggedOutPath(props.pageName);
  if (loggedOutPath) {
    actions.push({
      kind: 'button',
      key: 'copy-logged-out-link',
      text: 'Copy: Logged Out Link',
      icon: <CopyIcon />,
      disabled: props.section.anchor !== defaultValue?.anchor,
      onClick: () => {
        const url = new SiteBaseURL();
        url.pathname = loggedOutPath;
        url.hash = pageSectionAnchor(props.section);
        copy(url.toString());
      },
    });
  }

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-10 h-10 border border-secondary rounded-xl !text-icon-gray'
    />
  );
}

function PageRowContainer(props: {
  pageName: EnumsPageName;
  section: DtoPageSection;
  row: DtoPageRow;
  drag: ConnectDragSource;
  onDelete: () => void;
  onEdit: (update: DtoPageRow) => void;
}) {
  const { row } = props;

  switch (row.type) {
    case EnumsPageRowType.PageRowTypeTag:
      if (!row.tag) return null;
      return <PageMangerRowCategory {...props} tag={row.tag} />;
    case EnumsPageRowType.PageRowTypeTagCollections:
      if (!row.tagCollection) return null;
      return (
        <PageManagerRowTagCollection
          {...props}
          collection={row.tagCollection}
        />
      );
    case EnumsPageRowType.PageRowTypeFeaturedCarousel:
      return <PageMangerRowFeaturedCarousel {...props} />;
    case EnumsPageRowType.PageRowTypeSocialProof:
      return <PageMangerRowSocialProof {...props} />;
    default:
      assertExhaustive(row.type);
      return null;
  }
}

export function AddRowButton(props: {
  rows: DtoPageRow[];
  onAddRow: (row: DtoPageRow) => void;
}) {
  const { rows, onAddRow } = props;

  const triggerRowCategoryModal = useTriggerEditPageManagerRowCategoryModal();
  const triggerRowTagCollectionModal = useTriggerEditTagCollectionModal();
  const { watch } = useFormContext<DtoPage>();
  const sections = watch('sections');

  const [showMenu, setShowMenu] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => setShowMenu(false));

  const handleAddFeaturedCategory = () => {
    setShowMenu(false);
    triggerRowCategoryModal({
      filter: (tag) => !rows.some((row) => row.tag?.id === tag.id),
      onSave: (row) => onAddRow(row),
    });
  };

  const handleAddCategoryTray = () => {
    setShowMenu(false);
    triggerRowTagCollectionModal({
      onSave: (row) => onAddRow(row),
    });
  };

  const hasFeaturedCarousel = !!sections?.some((section) =>
    section.rows?.some(
      (row) => row.type === EnumsPageRowType.PageRowTypeFeaturedCarousel
    )
  );
  const handleAddFeaturedCarousel = () => {
    setShowMenu(false);
    onAddRow({
      type: EnumsPageRowType.PageRowTypeFeaturedCarousel,
      id: uuidv4(),
      anchor: '',
    });
  };

  const handleAddSocialProof = () => {
    setShowMenu(false);
    onAddRow({
      type: EnumsPageRowType.PageRowTypeSocialProof,
      id: uuidv4(),
      anchor: '',
    });
  };

  return (
    <div className='relative' ref={ref}>
      <button
        type='button'
        onClick={() => setShowMenu(!showMenu)}
        className='btn w-10 h-10 bg-black border border-secondary rounded-xl flex justify-center items-center text-secondary'
      >
        <PlusIcon />
      </button>

      {showMenu && (
        <div className='absolute right-full mr-2 -top-1'>
          <div
            className='
            bg-black border border-secondary rounded-lg 
            text-white flex flex-col gap-1 p-1 z-50 
            transition-opacity whitespace-nowrap
          '
          >
            <button
              type='button'
              className={'btn text-sms rounded-lg h-8 px-4 hover:bg-dark-gray'}
              onClick={handleAddFeaturedCategory}
            >
              Featured Category
            </button>
            <button
              type='button'
              className={'btn text-sms rounded-lg h-8 px-4 hover:bg-dark-gray'}
              onClick={handleAddCategoryTray}
            >
              Category Tray
            </button>
            <button
              type='button'
              className='btn text-sms rounded-lg h-8 px-4 hover:bg-dark-gray'
              onClick={handleAddFeaturedCarousel}
              disabled={hasFeaturedCarousel}
            >
              Featured Carousel
            </button>
            <button
              type='button'
              className='btn text-sms rounded-lg h-8 px-4 hover:bg-dark-gray'
              onClick={handleAddSocialProof}
            >
              Social Proof
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export function PageManagerSection(props: {
  pageName: EnumsPageName;
  section: DtoPageSection;
  drag: ConnectDragSource;
  onDelete: () => void;
  onEdit: (updated: DtoPageSection) => void;
}) {
  const { pageName, section, drag, onDelete, onEdit } = props;

  const triggerPageSectionEditorModal = useTriggerPageSectionEditorModal();

  const handleEdit = () => {
    triggerPageSectionEditorModal({
      section,
      onSave: (updated) => {
        onEdit(updated);
      },
    });
  };

  const rows = section.rows || [];

  const handleUpdateRows = (updatedRows: DtoPageRow[]) => {
    onEdit({
      ...section,
      rows: updatedRows,
    });
  };

  const handleAddRow = (row: DtoPageRow) => {
    const updatedRows = [row, ...rows];
    handleUpdateRows(updatedRows);
  };

  const handleRemoveRow = (index: number) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    handleUpdateRows(updatedRows);
  };

  const handleUpdateRow = (index: number, updated: DtoPageRow) => {
    const updatedRows = [...rows];
    updatedRows[index] = updated;
    handleUpdateRows(updatedRows);
  };

  const moveRow = (from: number, to: number) => {
    const updatedRows = [...rows];
    const [row] = updatedRows.splice(from, 1);
    updatedRows.splice(to, 0, row);
    handleUpdateRows(updatedRows);
  };

  return (
    <div className={`w-full`}>
      <div
        className={`w-full h-15  px-4 py-2.5 flex justify-between items-center
          ${
            section.type === EnumsPageSectionType.PageSectionTypeProgram
              ? 'bg-green-001 bg-opacity-20'
              : 'bg-lp-gray-003'
          }
          `}
      >
        <div className='flex items-center gap-4'>
          <button type='button' ref={drag} className='btn cursor-move'>
            <MenuIcon />
          </button>
          <div className='flex items-center gap-2.5'>
            {section.icon?.media?.url && (
              <img
                src={section.icon?.media?.url}
                alt=''
                className='w-6 h-6 object-contain'
              />
            )}
            <div className='font-bold'>{section.title}</div>
          </div>
        </div>
        <div className='flex items-center gap-2'>
          {section.type === EnumsPageSectionType.PageSectionTypeGeneral && (
            <AddRowButton rows={rows} onAddRow={(row) => handleAddRow(row)} />
          )}
          <PageManagerSectionActions
            pageName={pageName}
            section={section}
            onEditSection={handleEdit}
            onDeleteSection={onDelete}
          />
        </div>
      </div>
      <div className='mt-5 w-full pl-12 pr-4 flex flex-col gap-2'>
        <DragDropList
          type={`page-manager-${pageName}-sections-${section.id}-rows`}
          items={rows}
          onMove={moveRow}
          render={({ item: row, index: rowIndex, drag, ref, style }) => (
            <div className='w-full' style={style} ref={ref}>
              <PageRowContainer
                pageName={pageName}
                section={section}
                row={row}
                drag={drag}
                onDelete={() => handleRemoveRow(rowIndex)}
                onEdit={(updated) => handleUpdateRow(rowIndex, updated)}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}
