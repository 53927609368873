import { useCallback } from 'react';
import {
  Controller,
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';
import Select from 'react-select';

import {
  type DtoPageSection,
  EnumsMediaScene,
  EnumsPageSectionLayoutStyle,
  EnumsPageSectionType,
} from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { fromMediaDTO } from '../../utils/api-dto';
import { uuidv4 } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { MediaUploader } from '../MediaUploader/MediaUploader';

type FormData = DtoPageSection;

const TYPE_OPTIONS: Option<EnumsPageSectionType>[] = [
  {
    value: EnumsPageSectionType.PageSectionTypeGeneral,
    label: 'General',
  },
  {
    value: EnumsPageSectionType.PageSectionTypeProgram,
    label: 'Program',
  },
];

function TypeField(props: { disabled: boolean }) {
  const {
    field: { value, onChange },
  } = useController<FormData, 'type'>({
    name: 'type',
  });

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsPageSectionType>>({
      override: { control: { height: 40 } },
    })
  );

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Type</h2>

      <Select<Option<EnumsPageSectionType>>
        options={TYPE_OPTIONS}
        value={TYPE_OPTIONS.find((o) => o.value === value) || TYPE_OPTIONS[0]}
        classNamePrefix='select-box-v2'
        className='w-full'
        styles={styles}
        onChange={(option) => {
          if (!option) return;
          onChange(option.value);
        }}
        isDisabled={props.disabled}
      />
    </label>
  );
}

function TitleField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Title</h2>
      <textarea
        className={`w-full h-15 ${
          errors.title ? 'field-error' : 'field'
        } mb-0 py-2`}
        placeholder='Title'
        {...register('title', {
          required: true,
        })}
      ></textarea>
    </label>
  );
}

function SubtitleField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Subtitle</h2>
      <textarea
        className={`w-full h-15 ${
          errors.subtitle ? 'field-error' : 'field'
        } mb-0 py-2`}
        placeholder='Subtitle'
        {...register('subtitle', {})}
      ></textarea>
    </label>
  );
}

function AnchorField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label>
      <h2 className='text-base font-bold'>Anchor</h2>
      <span className='text-xs text-icon-gray'>
        It is used to link to the section from other pages
      </span>
      <input
        className={`mt-1 w-full h-12.5 ${
          errors.anchor ? 'field-error' : 'field'
        } mb-0`}
        {...register('anchor', {
          pattern: /^[a-z0-9-]+$/,
        })}
        placeholder='only lowercase letters, numbers, and hyphens'
      ></input>
    </label>
  );
}

function IconField() {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Icon</div>
      <Controller<FormData, 'icon'>
        name='icon'
        render={({ field }) => (
          <MediaUploader
            video={false}
            scene={EnumsMediaScene.MediaScenePageSection}
            media={fromMediaDTO(field.value?.media)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                data: { id: media.id },
              });
            }}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
            objectFit='object-contain'
          />
        )}
      />
    </div>
  );
}

const LAYOUT_STYLES_OPTIONS: Option<EnumsPageSectionLayoutStyle>[] = [
  {
    value: EnumsPageSectionLayoutStyle.PageSectionLayoutStyleMediaCollapse,
    label: 'Media Collapse',
  },
  {
    value:
      EnumsPageSectionLayoutStyle.PageSectionLayoutStyleMediaExpandedWithButton,
    label: 'Media Expanded',
  },
];

function LayoutStyleField() {
  const {
    field: { value, onChange },
  } = useController<FormData, 'layoutStyle'>({
    name: 'layoutStyle',
  });

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsPageSectionLayoutStyle>>({
      override: { control: { height: 40 } },
    })
  );

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Layout Style</h2>

      <Select<Option<EnumsPageSectionLayoutStyle>>
        options={LAYOUT_STYLES_OPTIONS}
        value={LAYOUT_STYLES_OPTIONS.find((o) => o.value === value)}
        classNamePrefix='select-box-v2'
        className='w-full'
        styles={styles}
        onChange={(option) => {
          if (!option) return;
          onChange(option.value);
        }}
      />
    </label>
  );
}

function ShowSocialProofField() {
  const {
    field: { value, onChange },
  } = useController<FormData, 'showSocialProof'>({
    name: 'showSocialProof',
  });

  const options = useInstance(() => [
    { value: true, label: 'On' },
    { value: false, label: 'Off' },
  ]);

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<boolean>>({
      override: { control: { height: 40 } },
    })
  );

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Social Proof</h2>
      <Select<Option<boolean>>
        options={options}
        value={options.find((o) => o.value === value)}
        classNamePrefix='select-box-v2'
        className='w-full'
        styles={styles}
        onChange={(option) => {
          if (!option) return;
          onChange(option.value);
        }}
      />
    </label>
  );
}

function ShowDemoButtonField() {
  const {
    field: { value, onChange },
  } = useController<FormData, 'showDemoButton'>({
    name: 'showDemoButton',
  });

  const options = useInstance(() => [
    { value: true, label: 'On' },
    { value: false, label: 'Off' },
  ]);

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<boolean>>({
      override: { control: { height: 40 } },
    })
  );

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Demo Button</h2>
      <Select<Option<boolean>>
        options={options}
        value={options.find((o) => o.value === value)}
        classNamePrefix='select-box-v2'
        className='w-full'
        styles={styles}
        onChange={(option) => {
          if (!option) return;
          onChange(option.value);
        }}
      />
    </label>
  );
}

function VideoTextFiled() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>Video CTA Text</h2>
      <input
        className={`w-full h-12.5 ${
          errors.videoText ? 'field-error' : 'field'
        } mb-0`}
        placeholder='CTA text'
        {...register('videoText', {})}
      ></input>
    </label>
  );
}

function VideoField() {
  return (
    <div className='font-bold block w-full'>
      <div className='mb-2'>Video</div>
      <Controller<FormData, 'video'>
        name='video'
        render={({ field }) => (
          <MediaUploader
            video
            scene={EnumsMediaScene.MediaScenePageSection}
            media={fromMediaDTO(field.value?.media)}
            onUploadSuccess={(media) => {
              field.onChange({
                media,
                data: { id: media.id },
              });
            }}
            onMediaDelete={() => {
              field.onChange(null);
            }}
            width='w-full'
            objectFit='object-contain'
          />
        )}
      />
    </div>
  );
}

function UrlTextField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>URL Text</h2>
      <input
        className={`w-full h-12.5 ${
          errors.url?.text ? 'field-error' : 'field'
        } mb-0`}
        placeholder='URL Text'
        {...register('url.text')}
      ></input>
    </label>
  );
}

function URLField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label>
      <h2 className='text-base font-bold mb-1'>URL</h2>
      <input
        className={`w-full h-12.5 ${
          errors.url?.url ? 'field-error' : 'field'
        } mb-0`}
        placeholder='/program or https://example.com'
        {...register('url.url')}
      ></input>
    </label>
  );
}

function HideFromNavField() {
  const {
    field: { value, onChange },
  } = useController<FormData, 'hideFromNav'>({
    name: 'hideFromNav',
  });

  return (
    <label className='flex items-center gap-2 cursor-pointer'>
      <input
        type='checkbox'
        className='w-5 h-5 checkbox-dark'
        checked={!value}
        onChange={(e) => onChange(!e.target.checked)}
      />
      <h2 className='text-base font-bold'>Show in Navigation</h2>
    </label>
  );
}

function makeDefaultPageSection(): DtoPageSection {
  return {
    id: uuidv4(),
    type: EnumsPageSectionType.PageSectionTypeGeneral,
    title: '',
    subtitle: '',
    anchor: '',
    icon: null,
    videoText: '',
    video: null,
    url: null,
    layoutStyle:
      EnumsPageSectionLayoutStyle.PageSectionLayoutStyleMediaCollapse,
    showDemoButton: false,
    showSocialProof: false,
    hideFromNav: false,
    rows: [],
  };
}

function PageSectionEditor(props: {
  section?: DtoPageSection | null;
  onSave: (updated: DtoPageSection) => void;
  onCancel: () => void;
}) {
  const { section, onSave, onCancel } = props;

  const form = useForm<DtoPageSection>({
    defaultValues: section ?? makeDefaultPageSection(),
  });
  const layoutStyle = form.watch('layoutStyle');

  return (
    <FormProvider {...form}>
      <form
        className='w-full px-7.5 py-5 flex flex-col items-center'
        onSubmit={form.handleSubmit(onSave)}
      >
        <header className='text-white text-2xl text-center'>
          {section ? 'Edit' : 'Add'} Section
        </header>
        <main className='mt-5 w-full flex flex-col gap-4'>
          <div className='grid grid-cols-2 gap-x-4'>
            <TypeField disabled={!!section} />
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='flex flex-col gap-5'>
              <TitleField />
              <SubtitleField />
              <AnchorField />
            </div>
            <IconField />
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <LayoutStyleField />
          </div>
          {layoutStyle ===
            EnumsPageSectionLayoutStyle.PageSectionLayoutStyleMediaExpandedWithButton && (
            <div className='grid grid-cols-2 gap-x-4'>
              <ShowSocialProofField />
              <ShowDemoButtonField />
            </div>
          )}
          <div className='grid grid-cols-2 gap-x-4'>
            <UrlTextField />
            <URLField />
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <VideoTextFiled />
            <VideoField />
          </div>

          <HideFromNavField />
        </main>
        <footer className='mt-5'>
          <div className='w-full flex justify-center items-center gap-2'>
            <button
              type='button'
              className='btn-secondary w-30 h-10'
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn-primary w-30 h-10 mx-1 flex items-center justify-center gap-1'
              disabled={form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? 'Saving' : 'Save'}
            </button>
          </div>
        </footer>
      </form>
    </FormProvider>
  );
}

export function useTriggerPageSectionEditorModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: {
      section?: DtoPageSection | null;
      onSave?: (updated: DtoPageSection) => void;
    }) => {
      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            containerClassName='w-192'
            borderStyle='gray'
            onClose={p.internalOnCancel}
          >
            <PageSectionEditor
              section={props.section}
              onSave={(updated) => {
                props.onSave?.(updated);
                p.internalOnConfirm();
              }}
              onCancel={p.internalOnCancel}
            />
          </ModalWrapper>
        ),
      });
    },
    [triggerModal]
  );
}
