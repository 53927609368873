import upperFirst from 'lodash/upperFirst';
import Select from 'react-select';

import { EnumsPageRowLayout } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../hooks/useInstance';
import { buildReactSelectStyles } from '../../utils/react-select';
import { type Option } from '../common/Utilities';

export function PageRowLayoutSelect(props: {
  value: EnumsPageRowLayout | null;
  onChange: (value: EnumsPageRowLayout) => void;
}) {
  const { value, onChange } = props;

  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsPageRowLayout>>({
      override: { control: { height: 40 } },
    })
  );

  const options = Object.values(EnumsPageRowLayout).map((value) => ({
    value,
    label: upperFirst(value),
  }));

  return (
    <Select<Option<EnumsPageRowLayout>>
      options={options}
      value={
        value
          ? {
              value,
              label: upperFirst(value),
            }
          : null
      }
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        onChange(option.value);
      }}
    />
  );
}
