import copy from 'copy-to-clipboard';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { type ConnectDragSource } from 'react-dnd';
import {
  FormProvider,
  useController,
  useForm,
  useFormContext,
} from 'react-hook-form';

import {
  type DtoPageRow,
  type DtoPageSection,
  type DtoTag,
  type DtoTagCollection,
  type EnumsPageName,
  EnumsPageRowLayout,
  EnumsPageRowType,
} from '@lp-lib/api-service-client/public';

import { pageRowAnchor } from '../../../app/components/DynamicPage/utils';
import { SiteBaseURL } from '../../services/public';
import { type Tag, TaggedObjectType } from '../../types';
import { toDtoTag } from '../../utils/api-dto';
import { uuidv4 } from '../../utils/common';
import { type Action, ActionSheet } from '../ActionSheet';
import { DragDropList } from '../common/DragDrop';
import { useAwaitFullScreenConfirmCancelModal } from '../ConfirmCancelModalContext';
import { ModalWrapper } from '../ConfirmCancelModalContext/ModalWrapper';
import { CopyIcon } from '../icons/CopyIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { TagUtils } from '../Tagging';
import { useTriggerTagEditorModal } from '../Tagging/TagEditor';
import { useTriggerCategorySelectionModal } from './PageManagerRowCategory';
import { type PageFormData } from './SectionedPageManager';
import { PageRowLayoutSelect } from './Shared';
import { PageManagerUtils } from './utils';

function formatTag(tag: Tag) {
  const count = TagUtils.GetObjectsCount(tag, TaggedObjectType.PrimeGamePack);
  const label = TagUtils.GetObjectLabel(TaggedObjectType.PrimeGamePack, false);
  return pluralize(label, count, true);
}

function PageMangerItemCategory(props: {
  tag: Tag;
  drag: ConnectDragSource;
  onDelete: () => void;
  onEdit: (updated: Tag) => void;
}) {
  const { tag, drag, onDelete, onEdit } = props;

  const triggerEditorModal = useTriggerTagEditorModal();

  const handleEdit = () => {
    triggerEditorModal({
      tag,
      onSave: (updated) => {
        onEdit(updated);
      },
    });
  };

  return (
    <div className={`w-full flex items-center gap-2`}>
      <button type='button' ref={drag} className='w-3.5 h-3.5 btn cursor-move'>
        <MenuIcon />
      </button>
      <div className='flex-1 flex items-center justify-between p-3 h-10 border border-secondary rounded-xl'>
        <div className='text-sms'>{tag.name}</div>
        <div className='text-sms text-secondary'>{formatTag(tag)}</div>
      </div>
      <button
        type='button'
        className='btn w-10 h-10 bg-black border border-secondary rounded-xl flex items-center justify-center text-red-002'
        onClick={onDelete}
      >
        <DeleteIcon />
      </button>
      <button
        type='button'
        className='btn w-10 h-10 bg-black border border-secondary rounded-xl flex items-center justify-center text-secondary'
        onClick={handleEdit}
      >
        <EditIcon />
      </button>
    </div>
  );
}

function PageManagerRowTagCollectionActions(props: {
  pageName: EnumsPageName;
  section: DtoPageSection;
  row: DtoPageRow;
  onEditRow: () => void;
  onEditTag: () => void;
  onDelete: () => void;
}) {
  const { formState } = useFormContext<PageFormData>();
  const defaultSection = formState.defaultValues?.sections?.find(
    (s) => s?.id === props.section.id
  );
  const defaultRow = defaultSection?.rows?.find((r) => r?.id === props.row.id);

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit-row',
      icon: <EditIcon />,
      text: 'Edit Row',
      onClick: props.onEditRow,
    },
    {
      kind: 'button',
      key: 'delete-row',
      icon: <DeleteIcon />,
      text: 'Delete Row',
      className: 'text-red-002',
      onClick: props.onDelete,
    },
  ];
  const loggedInPath = PageManagerUtils.GetLoggedInPath(props.pageName);
  if (loggedInPath) {
    actions.push({
      kind: 'button',
      key: 'copy-logged-in-link',
      text: 'Copy: Logged In Link',
      icon: <CopyIcon />,
      disabled: props.row.anchor !== defaultRow?.anchor,
      onClick: () => {
        const url = new SiteBaseURL();
        url.pathname = loggedInPath;
        url.hash = pageRowAnchor(props.row);
        copy(url.toString());
      },
    });
  }
  const loggedOutPath = PageManagerUtils.GetLoggedOutPath(props.pageName);
  if (loggedOutPath) {
    actions.push({
      kind: 'button',
      key: 'copy-logged-out-link',
      text: 'Copy: Logged Out Link',
      icon: <CopyIcon />,
      disabled: props.row.anchor !== defaultRow?.anchor,
      onClick: () => {
        const url = new SiteBaseURL();
        url.pathname = loggedOutPath;
        url.hash = pageRowAnchor(props.row);
        copy(url.toString());
      },
    });
  }

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-10 h-10 border border-secondary rounded-xl !text-secondary'
    />
  );
}

export function PageManagerRowTagCollection(props: {
  pageName: EnumsPageName;
  section: DtoPageSection;
  row: DtoPageRow;
  collection: DtoTagCollection;
  drag: ConnectDragSource;
  onDelete: () => void;
  onEdit: (updated: DtoPageRow) => void;
}) {
  const { pageName, section, row, collection, drag, onDelete, onEdit } = props;

  const triggerCategorySelectionModal = useTriggerCategorySelectionModal();
  const triggerEditTagCollectionModal = useTriggerEditTagCollectionModal();

  const handleEdit = () => {
    triggerEditTagCollectionModal({
      row,
      onSave: onEdit,
    });
  };

  const tags = collection.tags || [];
  const handleUpdateTags = (tags: DtoTag[]) => {
    onEdit({
      ...row,
      tagCollection: {
        ...collection,
        tags,
        ids: tags.map((t) => String(t.id)),
      },
    });
  };

  const handleAddTag = () => {
    triggerCategorySelectionModal({
      filter: (tag) => !row.tagCollection?.tags?.some((t) => t.id === tag.id),
      onSave: (tag) => {
        handleUpdateTags([toDtoTag(tag), ...tags]);
      },
    });
  };

  const handleMoveTag = (from: number, to: number) => {
    const updatedTags = [...tags];
    const [removed] = updatedTags.splice(from, 1);
    updatedTags.splice(to, 0, removed);
    handleUpdateTags(updatedTags);
  };

  const handleRemoveTag = (index: number) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    handleUpdateTags(updatedTags);
  };

  const handleUpdateTag = (index: number, updated: Tag) => {
    const updatedTags = [...tags];
    updatedTags[index] = toDtoTag(updated);
    handleUpdateTags(updatedTags);
  };

  return (
    <div
      className={`w-full bg-layer-001 rounded-xl overflow-hidden border border-secondary`}
    >
      <div className='w-full h-15 bg-layer-002 px-4 py-2.5 flex justify-between items-center'>
        <div className='flex items-center gap-4'>
          <button type='button' ref={drag} className='btn cursor-move'>
            <MenuIcon />
          </button>
          <div className='font-bold'>{row.tagCollection?.name}</div>
        </div>
        <div className='flex items-center gap-2'>
          <button
            type='button'
            onClick={handleAddTag}
            className='btn w-10 h-10 bg-black border border-secondary rounded-xl flex justify-center items-center text-secondary'
          >
            <PlusIcon />
          </button>
          <PageManagerRowTagCollectionActions
            pageName={pageName}
            section={section}
            row={row}
            onEditRow={handleEdit}
            onEditTag={handleAddTag}
            onDelete={onDelete}
          />
        </div>
      </div>
      <div className='w-full pl-12 pr-4 py-3 flex flex-col gap-2'>
        <DragDropList
          type={`page-manager-${pageName}-rows-${row.id}-tags`}
          items={collection.tags || []}
          onMove={handleMoveTag}
          render={({ item, index, drag, ref, style }) => (
            <div className='w-full' style={style} ref={ref}>
              <PageMangerItemCategory
                tag={item}
                drag={drag}
                onDelete={() => handleRemoveTag(index)}
                onEdit={(updated) => handleUpdateTag(index, updated)}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}

type FormData = {
  name: string;
  layout: EnumsPageRowLayout;
  anchor: string;
};

function LayoutField() {
  const {
    field: { value, onChange },
  } = useController<FormData, 'layout'>({
    name: 'layout',
    rules: { required: true },
  });

  return (
    <label>
      <div className='text-white text-sms mb-1'>Layout</div>
      <PageRowLayoutSelect value={value} onChange={onChange} />
    </label>
  );
}

function AnchorField() {
  const {
    formState: { errors },
    register,
  } = useFormContext<FormData>();

  return (
    <label title='The anchor is the unique identifier for the section. It is used to link to the section from other sections or pages.'>
      <h2 className='text-sms mb-1'>
        Anchor{' '}
        <span className='text-xs text-icon-gray'>
          It is used to link to the row from other pages.
        </span>
      </h2>
      <input
        className={`w-full h-10 ${
          errors.anchor ? 'field-error' : 'field'
        } mb-0`}
        {...register('anchor', {
          pattern: /^[a-z0-9-]+$/,
        })}
        placeholder='only lowercase letters, numbers, and hyphens'
      ></input>
    </label>
  );
}

function PageManagerRowTagCollectionEditor(props: {
  row?: DtoPageRow;
  onCancel: () => void;
  onConfirm: (row: DtoPageRow) => void;
}) {
  const { row, onCancel, onConfirm } = props;

  const form = useForm<FormData>({
    defaultValues: {
      name: row?.tagCollection?.name || '',
      layout: row?.layout || EnumsPageRowLayout.PageRowLayoutCarousel,
      anchor: row?.anchor || '',
    },
  });

  const handleSubmit = (data: FormData) => {
    if (!row) {
      onConfirm({
        id: uuidv4(),
        type: EnumsPageRowType.PageRowTypeTagCollections,
        layout: data.layout,
        anchor: data.anchor,
        tagCollection: {
          name: data.name,
          tags: [],
          ids: [],
        },
      });
      return;
    }

    onConfirm({
      ...row,
      layout: data.layout,
      anchor: data.anchor,
      tagCollection: {
        ...row.tagCollection,
        name: data.name,
      },
    });
  };

  return (
    <FormProvider {...form}>
      <form
        className='w-full px-8 py-10'
        onSubmit={form.handleSubmit(handleSubmit)}
      >
        <div className='text-white text-2xl text-center'>
          {row ? 'Edit' : 'Add'} Category Tray Row
        </div>

        <div className='mt-5 flex flex-col gap-4'>
          <label>
            <div className='text-white text-sms mb-1'>Name</div>
            <input
              type='text'
              className='w-full h-10 field mb-0'
              placeholder='Category Tray Name'
              maxLength={200}
              {...form.register('name', { required: true })}
              autoFocus
            />
          </label>

          <LayoutField />

          <AnchorField />
        </div>

        <div className='mt-12.5 flex justify-center items-center gap-10'>
          <button
            type='button'
            className='btn-secondary w-33 h-10'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button type='submit' className='btn-primary w-33 h-10'>
            Save
          </button>
        </div>
      </form>
    </FormProvider>
  );
}

export function useTriggerEditTagCollectionModal() {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (props: { row?: DtoPageRow; onSave: (row: DtoPageRow) => void }) => {
      await triggerModal({
        kind: 'custom',
        element: (p) => (
          <ModalWrapper
            onClose={p.internalOnCancel}
            containerClassName='w-120'
            borderStyle='gray'
          >
            <PageManagerRowTagCollectionEditor
              row={props.row}
              onCancel={p.internalOnCancel}
              onConfirm={(row) => {
                props.onSave(row);
                p.internalOnConfirm();
              }}
            />
          </ModalWrapper>
        ),
      });
    },
    [triggerModal]
  );
}
