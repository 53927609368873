import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';

import {
  type DtoPage,
  type EnumsPageName,
  type ModelsSettingsPage,
} from '@lp-lib/api-service-client/public';

import { PageDisplayName } from '../../../app/components/DynamicPage/utils';
import {
  fromMediaDataDTO,
  fromMediaDTO,
  toMediaDataDTO,
  toMediaDTO,
} from '../../utils/api-dto';
import { DragDropList } from '../common/DragDrop';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { MiniMediaEditor } from '../MediaUploader/MiniMediaEditor';
import { PageManagerSection } from './PageManagerSection';
import { useTriggerPageSectionEditorModal } from './PageManagerSectionEditor';
import { PageManagerUtils } from './utils';

export type PageFormData = DtoPage;

function BackgroundField() {
  return (
    <div className='font-bold block w-25'>
      <div className='mb-2'>Background</div>
      <Controller<PageFormData, 'background'>
        name='background'
        render={({ field }) => (
          <MiniMediaEditor
            id={`page-manager-background`}
            video
            media={fromMediaDTO(field.value?.media)}
            mediaData={fromMediaDataDTO(field.value?.data)}
            objectFit='cover'
            onChange={(data, media) =>
              field.onChange({
                media: media ? toMediaDTO(media) : null,
                data: toMediaDataDTO(data),
              })
            }
          />
        )}
      />
    </div>
  );
}

export function SectionedPageManager(props: {
  pageName: EnumsPageName;
  page: DtoPage;
  onCancel: () => void;
  onConfirm: (page: ModelsSettingsPage) => Promise<void>;
}) {
  const triggerSectionEditorModal = useTriggerPageSectionEditorModal();

  const form = useForm<PageFormData>({
    defaultValues: props.page,
  });
  const {
    fields: sections,
    prepend,
    remove,
    move,
    update,
  } = useFieldArray<PageFormData, 'sections', 'key'>({
    control: form.control,
    name: 'sections',
    keyName: 'key',
  });

  const handleAddSection = () => {
    triggerSectionEditorModal({
      onSave(updated) {
        prepend(updated);
      },
    });
  };

  const { isDirty, isSubmitSuccessful } = form.formState;

  const handleSubmit = form.handleSubmit((data) => {
    props.onConfirm(PageManagerUtils.DTOToModel(data));
    form.reset(data);
  });

  return (
    <FormProvider {...form}>
      <form className='w-full p-10' onSubmit={handleSubmit}>
        <div className='w-full flex justify-between items-center'>
          <div className='text-2xl font-medium'>
            {PageDisplayName(props.pageName)}
          </div>
          <div className='flex items-center gap-5'>
            <div className='text-icon-gray text-sms'>
              {isSubmitSuccessful && !isDirty && (
                <div className='flex items-center gap-1'>
                  <FilledCheckIcon />
                  Saved
                </div>
              )}
            </div>

            <button
              type='button'
              className='btn-secondary w-33 h-10'
              onClick={props.onCancel}
            >
              {isDirty ? 'Cancel' : 'Close'}
            </button>
            <button
              type='submit'
              disabled={form.formState.isSubmitting || !isDirty}
              className='btn-primary w-33 h-10'
            >
              {form.formState.isSubmitting ? 'Saving' : 'Save'}
            </button>
          </div>
        </div>

        <div className='mt-20 relative w-full flex justify-center'>
          <div className='absolute right-0 top-0'>
            <BackgroundField />
          </div>
          <div className='w-180 flex flex-col gap-10'>
            <div className='flex justify-end items-center gap-10'>
              <button
                type='button'
                className='btn text-primary font-medium'
                onClick={handleAddSection}
              >
                + Add Section
              </button>
            </div>
            <div className='w-full flex flex-col items-center gap-7.5'>
              <DragDropList
                type={`page-manager-${props.pageName}-sections`}
                items={sections}
                onMove={move}
                render={({ item: section, index, drag, ref, style }) => {
                  return (
                    <div className='w-full' style={style} ref={ref}>
                      <PageManagerSection
                        pageName={props.pageName}
                        section={section}
                        drag={drag}
                        onDelete={() => remove(index)}
                        onEdit={(updated) => update(index, updated)}
                      />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
